import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { Section, PageTitle, Text, Box, Button } from "../../components/Core";
import Sidebar from "../../sections/blog/Sidebar";
import { navigate } from "gatsby";
import { getProperty } from "../../utils/helperFn";
import moment from "moment";
import Seo from "../../components/Seo/Seo";
import CheckAvailability from "../../components/Rebrand/CheckAvailability/CheckAvailability";
import ReactMarkdown from 'react-markdown'

const BlogDetails = (props) => {

    const article = getProperty(props.data, 'strapiNewsArticles')

    const imageUrl = null;
    if (article?.image_content && article?.image_content?.childImageSharp) {
        const imageUrl = process.env.GATSBY_BASE_URL.slice(0, -1) + article.image_content.childImageSharp.resize.src;
    }
    
    return (
        <>
            <Seo page={article.slug} />
            <Section className="pb-5 pt-5 dark-bg" style={{overflow: 'hidden', position: 'relative'}}>
                { imageUrl && <img src={imageUrl} style={{ zIndex: '1', position: 'absolute', top: '-50%', left: '-100px', width: 'calc(100% + 200px)', filter: 'blur(15px) brightness(80%)'}} /> }
                <Container style={{position: 'relative', zIndex: '10'}}>
                    <Row>
                        <Col sm="12" md="9" className="text-left">
                            <h1 style={{textShadow: '1px 1px 1px rgba(0,0,0, 0.5)'}}>
                                {getProperty(article, 'title')}
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Section className="pb-5">
                <Container>
                    <Row>
                        <Col sm="12" md="2">
                            <h4>{moment(article.published_at).format('ll')}</h4>
                        </Col>
                        <Col sm="12" md="6">
                            <ReactMarkdown>{article.content}</ReactMarkdown>
                        </Col>
                        <Col sm="12" md="4">
                            <Sidebar />
                        </Col>
                        <Col sm="12" md="10" className="mt-5 text-center">
                            <Button onClick={() => navigate('/news/')} className="blue-bg">
                                Back to News
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <CheckAvailability center bg="green-bg" title={'Are we right up your street?'} />
        </>
    );
};

export const data = graphql`
    query getNewsArticleOnSlug($slug: String) {
      strapiNewsArticles(slug: {eq: $slug}) {
        id
        published_at
        content
        title
        slug
        image_alt
        image_content {
            childImageSharp {
              resize(width: 725) {
                src
              }
            }
          }
      }
    }`


export default BlogDetails;
